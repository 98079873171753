import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
// import decode from 'jsonwebtoken/decode';

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */'../views/LoginView.vue'),
    },
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */'../views/HomeView.vue'),
        beforeEnter: (to, from, next) => {
            if (store.state.token !== '') {
                next();
            } else {
                // next();
                next({ name: 'login' });
            }
        },
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    },
    {
        path: '/users',
        name: 'users',
        component: () => import(/* webpackChunkName: "users" */'../views/UsersView.vue'),
        beforeEnter: (to, from, next) => {
            if (store.state.token !== '') {
                next();
            } else {
                // next();
                next({ name: 'login' });
            }
        },
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
