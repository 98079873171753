import axios from 'axios';
import store from '@/store';

const { VUE_APP_API_URL } = process.env;

/**
 * 
 * @return {Promise<boolean>}
 */
export function isAdmin () {
    return axios
        .get(` ${VUE_APP_API_URL}/is-admin`, {
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': store.state.token,
            },
        })
        .then((response) => {
            if (response.status >= 400) throw new Error(response);
            return response.data.isAdmin;
        })
        .catch(() => false);
}