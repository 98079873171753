import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
});

export default createStore({
    state() {
        return {
            token: '',
        };
    },
    getters: {},
    mutations: {
        accessToken(state, token) {
            state.token = token;
        },
    },
    actions: {
        accessToken({ commit }, token) {
            commit('accessToken', token);
        },
    },
    modules: {},
    plugins: [vuexLocal.plugin]
});
