<template>
    <Bars3Icon
        class="fixed top-0 left-0 ml-3 mt-4 w-8 h-8 p-1  hover:rotate-90 transition duration-200 z-50"
        :class="{ 'text-gray-200': showSidebar, 'text-gray-600': !showSidebar }"
        @click="showSidebar = !showSidebar" />
    <Transition name="slide-fade">
        <div
            v-if="showSidebar"
            class="fixed top-0 left-0 z-40 w-48 h-screen transition-transform -translate-x-full sm:translate-x-0">
            <nav class="h-full px-3 py-4 overflow-y-auto bg-slate-600 space-y-2 font-medium">
                <div class="w-8 h-8">

                </div>
                <router-link to="/"> 
                    <div
                        class="rounded-lg flex items-center p-2 hover:bg-slate-400 text-gray-200 hover:text-white focus:text-white focus:outline transition duration-100">
                        Home
                    </div>
                </router-link>
                <router-link v-if="isAdmin" to="/users"> 
                    <div
                        class="rounded-lg flex items-center p-2 hover:bg-slate-400 text-gray-200 hover:text-white focus:text-white focus:outline transition duration-100">
                        Users 
                    </div>
                </router-link>
                <router-link to="/login">
                    <div
                        v-if="store.state.token == ''"
                        class="rounded-lg flex items-center p-2 hover:bg-slate-400 text-gray-200 hover:text-white focus:text-white focus:outline transition duration-100">
                        Login
                    </div>
                </router-link>
                <div
                    v-if="store.state.token !== ''"
                    class="rounded-lg flex items-center p-2 hover:bg-slate-400 text-gray-200 hover:text-white focus:text-white focus:outline transition duration-100" @click="logout()">Logout
                </div>
                <router-link to="/about"> 
                    <div
                        class=" mt-auto rounded-lg flex items-center p-2 hover:bg-slate-400 text-gray-200 hover:text-white focus:text-white focus:outline transition duration-100">
                        About 
                    </div>
                </router-link>
            </nav>
        </div>
    </Transition>
    <div class="h-full overflow-scroll" @click="showSidebar = false">
        <router-view />
    </div>
</template>

<script>
import store from '@/store';
import { ref } from 'vue';
import { Bars3Icon } from '@heroicons/vue/24/solid';
import router from '@/router';

import { isAdmin } from '@/utils/api';

export default {
    components: {
        Bars3Icon,
    },
    setup() {
        const login = ref('/login');
        const showSidebar = ref(true);
        const token = store.state.token;   
        const isAdmin = ref(false);     
        const logout = () => {
            store.dispatch('accessToken', '');
            router.push('/login');
        };
        if (token) {
            login.value = '/logout';
        }

        return {
            showSidebar,
            login,
            logout,
            store,
            isAdmin,
        };
    },
    watch: {
        store: {
            handler() {
                isAdmin().then((status) => {
                    this.isAdmin = status;
                });
            },
            deep: true,
        },
    },
    mounted() {
        isAdmin().then((status) => {
            this.isAdmin = status;
        });
    },
};

</script>

<style scoped>
.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(-192px);
}
</style>